import { RESTMethodPath } from '@commons/types/const'
import * as body from '@commons/types/interface'
import { type _GettersTree, defineStore } from 'pinia'

interface UserState {
  user: body.userInfo | null
  locale: string
}

interface UserGetters extends _GettersTree<UserState> {
  userInfo: (state: UserState) => body.userInfo | null
  currentLocale: (state: UserState) => string
}

interface UserActions {
  refreshUserInfo: () => Promise<body.userInfo>
  getUserInfo: (options?: { refresh: boolean }) => Promise<body.userInfo>
  clearUserInfo: () => Promise<void>
  isSubscriptionExpired: () => Promise<boolean>
  changeLocale: (locale: string) => Promise<void>
}

export const userUserStore = defineStore<'user', UserState, UserGetters, UserActions>('user', {
  state: () => ({
    user: null,
    locale: 'en'
  }),
  getters: {
    userInfo: (state: UserState) => state.user,
    currentLocale: (state: UserState) => state.locale || 'en'
  },
  actions: {
    async isSubscriptionExpired(): Promise<boolean> {
      const user = await this.getUserInfo({ refresh: true })
      if (!user || !user.subscription_end_at) return true
      const endTime = new Date(user.subscription_end_at).getTime()
      return endTime < Date.now() || user.subscription_type === body.SubscriptionType.NO_SUBSCRIPTION
    },
    async refreshUserInfo(): Promise<body.userInfo> {
      const resp = await request.get<body.userInfo>({
        url: RESTMethodPath.ME
      })

      if (!resp) {
        throw new Error('refresh user info failed')
      }

      this.user = resp
      return resp
    },
    async getUserInfo(options?: { refresh: boolean }): Promise<body.userInfo> {
      if ((options && options.refresh) || !this.user) {
        await this.refreshUserInfo()
      }

      if (!this.user) {
        throw new Error('get user info failed')
      }

      return this.user
    },
    async clearUserInfo() {
      this.user = null
    },
    async changeLocale(locale: string) {
      if (this.locale === locale) return

      const i18n = useNuxtApp().$i18n
      this.locale = locale

      i18n.setLocale(locale)
    }
  },
  persist: [
    {
      storage: persistedState.localStorage
    }
  ]
})
