interface subscription {
  first_subscription_at?: Date
  subscription_end_at?: Date
  subscription_homepage: string
  next_invoice_at?: Date
  subscription_type: SubscriptionType
  subscription_credit?: number
  stripe_stripe_currency?: string
}

interface userInfos {
  name: string
  email: string
  account: string
  lang: string
  avatar: string
  timezone: string
  id: string
  platform: bindedItem[]
  subscription: subscription
  aff_code?: string
}

interface userInfo {
  userId: number
  email: string
  lang: string
  name: string
  picture: string
  timezone: string
  subscription_type: SubscriptionType
  subscription_end_at: Date
}

interface bookmarkExistsReq {
  target_url: string
}

interface bookmarkExistsResp {
  exists: boolean
  parse_type: ParserType
  bookmark_id?: number
  bookmark_tags?: string[]
}

interface addBookmarkReq {
  target_url: string
  target_title: string
  target_icon: string
  taget_cover: string
  content: string
  tag: string[]
  description: string
}

interface reportFeedbackReq {
  bookmark_id: number
  content: string
  type: string
}

interface bindedItem {
  platform: string
  user_name: string
  created_at: Date
}

interface addBookmarkResp {
  bmId: number
}

interface emptyResp {}

export enum ParserType {
  UNKNOWN = 0,
  CLIENT_PARSE = 1,
  SERVER_FETCH_PARSE = 2,
  SERVER_PUPPETEER_PARSE = 3,
  BLOCK_PARSE = 4
}

export enum SubscriptionType {
  NO_SUBSCRIPTION = 0,
  FREE_SUBSCRIPTION = 1,
  PAID_SUBSCRIPTION = 2
}

export type { userInfo, addBookmarkReq, addBookmarkResp, bookmarkExistsReq, bookmarkExistsResp, emptyResp, reportFeedbackReq, bindedItem, userInfos }
